import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

import {
  colors, margins, fontSizes, mobileThresholdPixels, contentWidth, LineH,
}
  from '../home/v2/StyledComponents';
import { toFormattedColoredText, toFormattedBoldText } from '../../services/formatting';

const Container = styled.div`
  color: ${colors.navy};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: ${margins.m} 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
  }
`;

const Content = styled.div`
  width: ${contentWidth}px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    margin-bottom: ${margins.l};
    align-items: center;
    width: 100vw;
  }
`;

const KeywordsBlockContainer = styled.div`
  width: 300px;
  margin: ${(props) => props.margin};
  color: ${colors.darkGrey};
  font-size: ${fontSizes.s};
`;

const KeywordsContainer = styled.div`
  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
  }
`;

const Keyword = styled.span`line-height: 29px;`;

const ImgContainer = styled.div`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 90vw;
  }
`;

const Img1 = styled.img`
  z-index: 1;

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
`;

const ImgTextContainer = styled.div`
  margin: ${(props) => props.margin};
`;

const GreyBanner = styled.div`
  background-color: ${colors.lightGrey};
  position: absolute;
  left: 0px;
  margin-top: -5%;
  height: 270px;
  width: calc(100vw - ${margins.s});

  @media (max-width: ${mobileThresholdPixels}) {
    height: 130px;
    width: 100vw;
  }
`;

const ImgText = styled.h1`
  position: absolute;
  z-index: 1;
  font-size: ${(props) => props.fontSize};
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
`;

const Title3 = styled.h3`
  text-align: left;
  width: 100%;
  font-size: ${fontSizes.s};
  font-weight: 300;
  margin: 0px;

  @media (max-width: ${mobileThresholdPixels}) {
    text-align: center;
    font-size: 20px;
  }
`;

const StyledLineH = styled(LineH).attrs({ focus: true })`
  z-index: 1;
  width: 200px;
  margin: ${(props) => props.margin};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
`;

const Img2 = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 50vw;
  }
`;

const P = styled.p`
  background-color: ${colors.lightGrey};
  margin: ${(props) => props.margin};
  padding: ${margins.l};
  width: 40%;
  z-index: 2;

  @media (max-width: ${mobileThresholdPixels}) {
    padding-right: ${margins.s};
    font-size: ${fontSizes.s};
    padding-left: ${margins.s};
  }
`;

const LaRubriqueTemplate2TopBlock = ({
  keywords, keywordsContainerMargin, image, imageAlt, imageText,
  lineHMargin, imgTextStyle, row1Text, row1TextMargin, row1Image, row1ImageAlt,
}) => (
  <Container>
    <Content>
      <ImgContainer>
        <Img1 src={image} alt={imageAlt} />
        <ImgTextContainer margin={imgTextStyle.margin}>
          <GreyBanner />
          <ImgText fontSize={imgTextStyle.fontSize}>
            {toFormattedColoredText(imageText, 'yellow')}
            <StyledLineH margin={lineHMargin} />
          </ImgText>
        </ImgTextContainer>
      </ImgContainer>
      <KeywordsBlockContainer margin={keywordsContainerMargin}>
        <Title3>⋅ Mots-clés ⋅</Title3>
        <KeywordsContainer>
          {keywords.map((keyword) => <Keyword key={keyword}>{`#${keyword} `}</Keyword>)}
        </KeywordsContainer>
      </KeywordsBlockContainer>
    </Content>
    <Content>
      <Row>
        <P margin={row1TextMargin}>
          {toFormattedBoldText(row1Text)}
        </P>
        <Img2 src={row1Image} alt={row1ImageAlt} />
      </Row>
    </Content>
  </Container>
);

LaRubriqueTemplate2TopBlock.propTypes = {
  keywords: PropTypes.arrayOf(PropTypes.string).isRequired,
  keywordsContainerMargin: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  imageAlt: PropTypes.string.isRequired,
  imageText: PropTypes.string.isRequired,
  lineHMargin: PropTypes.string.isRequired,
  imgTextStyle: PropTypes.shape({
    fontSize: PropTypes.string.isRequired,
    margin: PropTypes.string.isRequired,
  }).isRequired,
  row1Text: PropTypes.string.isRequired,
  row1TextMargin: PropTypes.string.isRequired,
  row1Image: PropTypes.string.isRequired,
  row1ImageAlt: PropTypes.string.isRequired,
};

export default LaRubriqueTemplate2TopBlock;
