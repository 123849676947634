import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import { toFormattedText } from '../../services/formatting';
import routesMap from '../../Routes';
import {
  colors, margins, mobileThresholdPixels, fontSizes, Button,
}
  from '../home/v2/StyledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.navy};
  padding: ${margins.m} 0px;
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: ${margins.xl};

  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
  }
`;

const Title3 = styled.h3`
  font-size: ${fontSizes.x2l};
  white-space: pre;
  font-weight: 500;
  margin: 0px 0px ${margins.m};
  text-align: center;

  @media (max-width: ${mobileThresholdPixels}) {
    font-size: ${fontSizes.l};
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
`;

const Img1 = styled.img`
  @media (max-width: ${mobileThresholdPixels}) {
    width: 50vw;
  }
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  align-items: center;
  background-color: ${colors.lightGrey};
  padding: ${margins.m};
  margin: ${(props) => props.margin};
  width: 40%;

  @media (max-width: ${mobileThresholdPixels}) {
    padding: 0px;
    width: 45%;
  }
`;

const P = styled.p`
  z-index: 2;

  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    text-align: left;
    padding-right: ${margins.s};
    font-size: ${fontSizes.s};
    padding-left: ${margins.s};
  }
`;

const Span1 = styled.span`
  font-size: ${fontSizes.xl};
  font-weight: 500;
  margin: -9% 0% 0% -6%;
  align-self: flex-start;
`;

const Span2 = styled.span`text-align: center;`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${margins.l} 0px 0px;
`;

const LaRubriqueTemplate2BottomBlock = ({
  row4Image, row4ImageAlt, row4Text, row4TextMargin,
}) => (
  <Container>
    <Content>
      <Row>
        <Img1 src={row4Image} alt={row4ImageAlt} />
        <TextContainer margin={row4TextMargin}>
          <Span1>En bref ...</Span1>
          <P>{toFormattedText(row4Text)}</P>
        </TextContainer>
      </Row>
    </Content>
    <Content>
      <Title3>•  Prêt(e) à vous lancer ?  •</Title3>
      <Span2>
        Sinon nos tillistes sont disponibles pour retoucher
        <br />
        vos vêtements
      </Span2>
      <ButtonContainer>
        <Link to={routesMap.Step1.url}>
          <Button navy>réserver votre tilliste</Button>
        </Link>
      </ButtonContainer>
    </Content>
  </Container>
);

LaRubriqueTemplate2BottomBlock.propTypes = {
  row4Image: PropTypes.string.isRequired,
  row4ImageAlt: PropTypes.string.isRequired,
  row4Text: PropTypes.string.isRequired,
  row4TextMargin: PropTypes.string.isRequired,
};

export default LaRubriqueTemplate2BottomBlock;
