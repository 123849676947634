import React from 'react';
import styled from 'styled-components';
import { trackEvent } from '../../services/analytics';
import { Icon } from './homeStyledComponents';
import { colors, mobileThreshold } from '../styledComponents';
import facebook from '../../assets/facebook.svg';
import instagram from '../../assets/instagram.svg';
import InstaFeed from './InstaFeed';

const Container = styled.div`
  padding: 40px 0px 40px;
  background-color: ${colors.lightGrey};
`;

const P = styled.p`
  color: ${colors.navy};
  text-align: center;
  font-size: 18px;
`;

const Row = styled.div`
  text-align: center;
`;

const SocialNetworks = () => {
  const isMobile = (typeof window !== 'undefined') && window.innerWidth < mobileThreshold;
  return (
    <Container>
      <P>Ne perdez pas le fil, suivez toute l'actualité de Tilli sur facebook et instagram</P>
      <Row>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.facebook.com/tilli.couture/"
          onClick={() => trackEvent('click', 'facebook_reseaux-sociaux')}
        >
          <Icon src={facebook} alt="page facebook de Tilli un service de retouches à domicile" />
        </a>
        <a
          target="_blank"
          rel="noreferrer noopener"
          href="https://www.instagram.com/tilli.couture/"
          onClick={() => trackEvent('click', 'instagram_reseaux-sociaux')}
        >
          <Icon src={instagram} alt="page instagram de Tilli une retoucherie à domicile" />
        </a>
      </Row>
      <P>Nos dernières photos instagram :</P>
      <InstaFeed isMobile={isMobile} />
    </Container>
  );
};

export default SocialNetworks;
