import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Link } from 'gatsby';

import withAppContext from '../../withAppContext';
import { toFormattedColoredText, toFormattedBoldText, toFormattedText }
  from '../../services/formatting';
import routesMap from '../../Routes';
import {
  colors, margins, mobileThresholdPixels, fontSizes, contentWidth, Button,
}
  from '../home/v2/StyledComponents';

const Container = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${colors.navy};
  padding: ${margins.m} 0px;
`;

const Content = styled.div`
  width: ${contentWidth}px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 100vw;
  }
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  ${(props) => !props.noMargin && 'margin-top: -10px'};

  @media (max-width: ${mobileThresholdPixels}) {
    ${(props) => !props.columnOnMobile ? '' : `
    flex-direction: column;
    align-items: center;
    margin: 0px;
    `}
  }
`;

const img1Width = 300;
const Img1 = styled.img`
  width: ${img1Width}px;
  @media (max-width: ${mobileThresholdPixels}) {
    width: 50vw;
  }
`;

const P1 = styled.p`
  text-align: right;
  width: ${contentWidth - img1Width}px;
  padding-right: ${margins.l};
  z-index: 2;

  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    text-align: left;
    padding-right: ${margins.s};
    font-size: ${fontSizes.s};
    padding-left: ${margins.s};
  }
`;

const Img2Container = styled.div`
  display: flex;

  @media (max-width: ${mobileThresholdPixels}) {
    justify-content: flex-end;
  }
`;

const Img2 = styled.img`
  object-fit: contain;
  margin-top: ${(props) => props.image2TopMargin}px;
  margin-left: ${(props) => props.image2LeftMargin};

  @media (max-width: ${mobileThresholdPixels}) {
    margin: ${margins.m} 0px 0px;
    padding-left: 5vw;
    top: 0px;
  }
`;

const Img2Text = styled.span`
  font-size: ${(props) => props.fontSize};
  font-weight: 500;
  text-transform: uppercase;
  line-height: 1;
  text-align: ${(props) => props.textAlign || 'right'};
  margin: ${(props) => props.margin};
`;

const P2 = styled.p`
  text-align: left;
  margin-top: calc(40px - ${(props) => props.image2TopMargin}px);
  margin-left: ${(props) => props.p2LeftMargin};
  z-index: 2;

  @media (max-width: ${mobileThresholdPixels}) {
    width: auto;
    font-size: ${fontSizes.s};
    padding: 0px ${margins.s};
    text-align: center;
    margin: ${margins.m} 0px 0px;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin: ${margins.l} 0px 0px;
`;

const LaRubriqueTemplate1BottomBlock = ({
  text1, image1, image1Alt, text2, image2,
  image2Alt, image2TopMargin, image2LeftMargin,
  p2LeftMargin, image2Text, image2TextStyle, context: { isMobile },
}) => (
  <Container>
    <Content>
      <Row noMargin>
        <P1>{!isMobile ? toFormattedText(text1) : text1.split('\n').join('')}</P1>
        <Img1 src={image1} alt={image1Alt} />
      </Row>

      <Row columnOnMobile>
        <Img2Container>
          <Img2
            src={image2}
            alt={image2Alt}
            image2TopMargin={image2TopMargin}
            image2LeftMargin={image2LeftMargin}
          />
          <Img2Text
            fontSize={image2TextStyle.fontSize}
            margin={image2TextStyle.margin}
            textAlign={image2TextStyle.textAlign}
          >
            {toFormattedColoredText(image2Text, 'yellow')}
          </Img2Text>
        </Img2Container>
        <P2
          image2TopMargin={image2TopMargin}
          p2LeftMargin={p2LeftMargin}
        >
          {!isMobile
            ? toFormattedBoldText(text2)
            : text2.split('\n').join('').split('<bold>').join('')}
        </P2>
      </Row>
      <ButtonContainer>
        <Link to={routesMap.Step1.url}>
          <Button navy>réserver votre tilliste</Button>
        </Link>
      </ButtonContainer>
    </Content>
  </Container>
);

LaRubriqueTemplate1BottomBlock.propTypes = {
  text1: PropTypes.string.isRequired,
  image1: PropTypes.string.isRequired,
  image1Alt: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  image2: PropTypes.string.isRequired,
  image2Alt: PropTypes.string.isRequired,
  image2TopMargin: PropTypes.number.isRequired,
  image2LeftMargin: PropTypes.string.isRequired,
  p2LeftMargin: PropTypes.string.isRequired,
  image2Text: PropTypes.string.isRequired,
  image2TextStyle: PropTypes.shape({
    fontSize: PropTypes.string.isRequired,
    textAlign: PropTypes.string,
    margin: PropTypes.string.isRequired,
  }).isRequired,
  context: PropTypes.shape({
    isMobile: PropTypes.bool,
  }).isRequired,
};

export default withAppContext(LaRubriqueTemplate1BottomBlock);
